import React from 'react';
import { useStaticQuery } from 'gatsby';
import styles from './jam-banner.module.css';

export default function() {
  const data = useStaticQuery(graphql`
    query bannerQuery {
      allContentfulAsset(filter: {contentful_id: {eq: "azalj3tYiNtD9DxjaSQWi"}}) {
      edges {
        node {
          file {
            url
          }
        }
      }
    }
    }
  `)

  return (
    <div style={{textAlign:'center'}}>

    <img className={styles.banner} src={data.allContentfulAsset.edges[0].node.file.url}>
    </img>
    <h3>Outdoor Jam 2023</h3>
    <p style={{textAlign: 'center', maxWidth: '600px', margin: '0 auto'}}>Outdoor Jam 2023 is happening the weekend of October 20-22 in Zion National Park! <a href="mailto:shauna@benmyerfoundation.org" style={{textDecoration: 'underline'}}>Contact Shauna</a> if you have questions or think you might want to join!</p>
    </div>
  )
}
